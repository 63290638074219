import { Component, Mixins } from 'vue-property-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import template from './GestionPrescripteurs.Template.vue';
import { PrescripteurInterne } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { PagingAndSorting } from '@/shared/models';
import { PrescripteurInterneCriteria } from './PrescripteurInterneCriteria.model';

@Component({
    ...template,
    name: 'GestionPrescripteurs',
})
export default class GestionPrescripteurs extends Mixins(GrilleMixin) {
    private headers: any[];
    private prescripteurs: PrescripteurInterne[];
    private loading: boolean;
    public totalPrescripteurs: number;

    public prescripteursCriteria: PrescripteurInterneCriteria;

    constructor() {
        super();
        this.headers = [];
        this.prescripteurs = [];
        this.loading = false;
        this.prescripteursCriteria = new PrescripteurInterneCriteria();
        this.totalPrescripteurs = 0;
    }
    public created() {
        this.headers = [
            { text: 'Id', value: 'id', class: 'text-xs-center' },
            { text: 'Prenom', value: 'prenom', class: 'text-xs-center' },
            { text: 'Nom', value: 'nom', class: 'text-xs-center' },
            { text: 'E-mail', value: 'mail', class: 'text-xs-center' },
            { text: 'Entité Commerciale', value: 'entiteCommerciale', class: 'text-xs-center' },
            { text: 'Equipe', value: 'equipe', class: 'text-xs-center' },
            { text: 'Actif', value: 'actif', class: 'text-xs-center' },
        ];
        this.getData();
    }

    public getData() {
        this.prescripteursCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.getAllPrescripteurs(this.prescripteursCriteria);
    }

    public getAllPrescripteurs(criteria: PrescripteurInterneCriteria): Promise<PrescripteurInterne[]> {
        const fetchAllPrescripteursApi = new ApiService<PrescripteurInterne>('prescripteurInterne/obtenirTous');
        return new Promise<PrescripteurInterne[]>((resolve, reject) => {
            return fetchAllPrescripteursApi.getWhere(criteria.queryString).then((response) => {
                this.prescripteurs = response.data.data;
                this.totalPrescripteurs = response.data.totalCount;
                this.$set(this.pagination, 'totalItems', this.totalPrescripteurs);
                resolve(response.data.data);
            })
                .catch((error: { response: any; }) => {
                    reject(error.response);
                });
        });
    }
}
